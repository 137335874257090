import React, {useState} from 'react';
import helpIcon from '../svg/help_light.svg';
import Tippy from '@tippyjs/react';
import Autosuggest from 'react-autosuggest';
import {axiosOnce} from '../functions/axiosOnce';
import axios from 'axios';

export const FormAutosuggest = (options) => {
  const {fieldId, fieldName, required, handlers, errorText='Fehler', help=false} = options;
  const {inputChangeHandler, inputBlurHandler, input, errors} = handlers;

  const styleOK = 'shadow appearance-none border rounded py-2 px-3 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-md'
  const styleNOK = 'shadow appearance-none border rounded py-2 px-3 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-md border-red-500'

  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getSuggestionValue = (suggestion) => {
    return suggestion.plz
  }

  const renderSuggestion = (suggestion) => {
    return (
      <span>{suggestion.plz + ' ' + suggestion.ort}</span>
    )
  }

  const loadSuggestions = (value) => {
    setIsLoading(true)
    const payload = { plz: value }

    let config = {
      method: "post",
      data: payload,
      url: "/api/address/validate",
    }

    axiosOnce(config)
      .then (response => {
        setSuggestions(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        if(!axios.isCancel(error)) console.log('API error')
        setIsLoading(false)
      });
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
    inputChangeHandler(event)
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    loadSuggestions(value);
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const onSuggestionSelected = (event, suggestion) => {
    // function necessary to trigger input update and re-validation
    inputChangeHandler({target:{name:fieldId,value:suggestion.suggestionValue}})
  }

  const inputProps = {
    id: fieldId,
    name: fieldId,
    placeholder: "",
    value,
    onChange: onChange,
    onBlur: () => inputBlurHandler(fieldId),
    className: errors.has(fieldId) ? styleNOK : styleOK
  }

  return(
    <div className="mb-4">
      <div className="flex flex-row items-start items-center">
        <label className="text-gray-700 text-sm font-bold mx-2 w-20" htmlFor={fieldId}>{fieldName}{required ? '*':''}</label>
        <Autosuggest
          className="w-1/2"
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <div>
          { help
            ? <Tippy theme="light" content={
              <span >{help}</span>
            }>
              <img src={helpIcon} className="ml-3 w-5" alt="Hilfe" />
            </Tippy>
            : ''
          }
        </div>
      </div>
      <div className="text-red-700 text-xs mx-2 pl-20">{errors.has(fieldId) ? errorText : ''}</div>
    </div>
  )
}
