import React, {useState} from 'react';

const ConfiguratorFormInputAmount = (props) => {
  const {data, doorConfig, updateDoorConfig, handleNext} = props

  const defaults = {
    amount: 1,
  }
  const [input, setInput] = useState({
    ...defaults,
    ...doorConfig[data.pageName],
  })

  const inputChangeHandler = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setInput({
      ...input,
      [key]: value,
    })
  }

  const handleNextButton = () => {
    updateDoorConfig(data.pageName, input)
    handleNext()
  }

  return(
    <div className="flex flex-wrap flex-start justify-center mt-3 lg:mt-6 w-full">
      <form className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4 text-left">
        <div className="mb-4">
          <div className="flex flex-row items-start items-center">
            <label className="text-gray-500 text-sm font-bold mx-3 w-12" htmlFor="innerHeight">Anzahl</label>
            <input
              className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md"
              id="amount"
              name="amount"
              type="number"
              defaultValue={input.amount}
              onChange={(e) => inputChangeHandler(e)}
            />
            <div className="px-2 text-gray-500 text-sm">Stück</div>
          </div>
        </div>
        <div className="flex items-center justify-end mt-8">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3"
            type="button"
            onClick={handleNextButton}
          >
            weiter
          </button>
        </div>
      </form>
    </div>
  )
}

export default ConfiguratorFormInputAmount
