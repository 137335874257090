import React, {useState} from 'react';
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import {trackScreenView} from '../functions/analytics';
import {FormAutosuggest} from './FormAutosuggest';

const ConfiguratorFormInputZip = (props) => {
  const {data, doorConfig, updateDoorConfig, handleNext} = props

  const inputDefaults = {
    plz: '',
  }

  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set())
  const [forceUpdate, setForceUpdate] = useState(1)

  const inputChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.value
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    errors.has(key) ? validateInput(event.target) : null
  }

  const checkboxChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.checked
    setInput({
      ...input,
      [key]: value,
    })
    // validate on every change
    validateInput({name:key, value:value})
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    checkboxChangeHandler: checkboxChangeHandler,
    input: input,
    errors: errors,
  }

  const validateForm = () => {
    for(const field in inputDefaults) {
      validateInput({name:field, value:input[field]})
    }
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (name, value) => {
    let error;
    switch (name) {
      case 'plz':
        if (typeof(value) === 'undefined' || value === null || !RegExp('^[0-9]{4,5}$').test(value)) error = true; break;
      default:
        error = false;
    }
    return error;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    if(errors.size > 0) {
      trackScreenView('PLZ Validierungsfehler')
    } else {
      updateDoorConfig(data.pageName, input)
      handleNext()
      trackScreenView('PLZ gesendet')
    }
  }


  return(
    <div className="flex flex-wrap flex-start justify-center mt-3 lg:mt-6 w-full">
      <form id="zipform" onSubmit={handleSubmit} autoComplete="on" className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4 text-left">
        <div className="mb-4">

          <div className="block text-gray-600 text-md font-bold mb-2">
            Postleitzahl Ihres Bauvorhabens
          </div>

          <div className="flex flex-row items-start items-center">
            {FormAutosuggest({
              fieldId: 'plz',
              fieldName:'PLZ',
              required: true,
              handlers: handlers,
              errorText: 'Bitte geben Sie eine gültige Postleitzahl an.',
              help: 'Wir benötigen Ihre PLZ, um für Sie den besten Preis vor Ort zu ermitteln.'
            })}

          </div>
        </div>

        <div className="flex items-center justify-end mt-8">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3"
            type="submit"
          >
            weiter
          </button>
        </div>

      </form>
    </div>
  )
}

export default ConfiguratorFormInputZip
