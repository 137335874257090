import axios from './axios.config';

export const postStep = (payload) => {
  axios.post('/configurator/step',payload)
    .then ( response => {
      return true
    })
    .catch(error => {
      console.log('API error')
      return false
    });
}

