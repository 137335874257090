import automatiktuer_neu from '../images/konfigurator/automatiktuer_neu.png';
import automatiktuer_modernisiert from '../images/konfigurator/automatiktuer_modernisiert.png';
import einflueglig_links from '../images/konfigurator/automatiktuer_einflueglig_linksoeffnend_01.png';
import einflueglig_rechts from '../images/konfigurator/automatiktuer_einflueglig_rechtsoeffnend_01.png';
import zweiflueglig from '../images/konfigurator/automatiktuer_zweiflueglig_01.png';
import zweiflueglig_seitenfenster from '../images/konfigurator/automatiktuer_zweiflueglig_seitenfenster_01.png';
import weiss_nicht from '../images/konfigurator/weiss_nicht.png';
import automatiktuer_mit_oberlicht from '../images/konfigurator/automatiktuer_mit_oberlicht.png';
import automatiktuer_ohne_oberlicht from '../images/konfigurator/automatiktuer_ohne_oberlicht.png';
import fluchtweg from '../svg/fluchtweg.svg';
import einbruchhemmung from '../svg/einbruchhemmung.svg';
import energieeinsparung from '../svg/energieeinsparung.svg';
import design from '../svg/design.svg';
import entsorgung_ja from '../svg/entsorgung_ja.svg';
import entsorgung_nein from '../svg/entsorgung_nein.svg';
import kundenart_gewerblich from '../svg/kundenart_gewerblich.svg';
import kundenart_privat from '../svg/kundenart_privat.svg';

export default {
  pages:[
    {
      pageName: 'start',
      question: 'Was planen Sie zu bauen?',
      questionType: 'single',
      next: 'tuerart',
      options: [
        {
          id: 'neu',
          image: automatiktuer_neu,
          name: 'Neue Automatiktür',
          next: 'tuerart'
        },
        {
          id: 'modernisierung',
          image: automatiktuer_modernisiert,
          name: 'Modernisierung bestehender Automatiktür',
          next: 'entsorgung'
        }
      ]
    },
    {
      pageName: 'entsorgung',
      question: 'Soll die alte Automatiktür ausgebaut und entsorgt werden?',
      questionType: 'single',
      next: 'tuerart',
      options: [
        {
          id: 'ja',
          image: entsorgung_ja,
          imageClass: 'h-24 pt-5 mb-8 mx-auto',
          name: 'Ja',
        },
        {
          id: 'nein',
          image: entsorgung_nein,
          imageClass: 'h-24 pt-5 mb-8 mx-auto',
          name: 'Nein',
        }
      ]
    },
    {
      pageName: 'tuerart',
      question: 'Welchen Typ Schiebetür wünschen Sie?',
      questionType: 'single',
      next: 'verglasung',
      options: [
        {
          id: 'einflueglig-links',
          image: einflueglig_links,
          name: 'Einflüglige Tür (linksöffnend)',
        },
        {
          id: 'einflueglig-rechts',
          image: einflueglig_rechts,
          name: 'Einflüglige Tür (rechtsöffnend)',
        },
        {
          id: 'zweiflueglig',
          image: zweiflueglig,
          name: 'Zweiflüglige Tür',
        },
        {
          id: 'zweiflueglig-seitenfenster',
          image: zweiflueglig_seitenfenster,
          name: 'Zweiflüglige Tür mit Seitenfenster',
        },
        {
          id: 'page2-weiss-nicht',
          image: weiss_nicht,
          name: 'Weiß nicht',
        }
      ]
    },
    {
      pageName: 'verglasung',
      question: 'Benötigen Sie eine angrenzende Verglasung?',
      questionType: 'single',
      next: 'besonderheiten',
      options: [
        {
          id: 'oberlicht-ohne',
          image: automatiktuer_ohne_oberlicht,
          name: 'Ohne Oberlicht',
        },
        {
          id: 'oberlicht-mit',
          image: automatiktuer_mit_oberlicht,
          name: 'Mit Oberlicht',
        },
        {
          id: 'page3-weiss-nicht',
          image: weiss_nicht,
          name: 'Weiß nicht',
        }
      ]
    },
    {
      pageName: 'besonderheiten',
      question: 'Was ist Ihnen besonders wichtig bei Ihrer neuen Automatikschiebetür?',
      description: '- Mehrfachauswahl möglich -',
      questionType: 'multi',
      next: 'groesse',
      options: [
        {
          id: 'fluchtweg',
          image: fluchtweg,
          imageClass: 'w-24 h-24 pt-8 mb-12 mx-auto',
          name: 'Flucht- und Rettungsweg'
        },
        {
          id: 'einbruchsicherheit',
          image: einbruchhemmung,
          imageClass: 'w-24 h-24 pt-8 mb-12 mx-auto',
          name: 'Einbruch&#173;sicherheit'
        },
        {
          id: 'energieeffizienz',
          image: energieeinsparung,
          imageClass: 'w-24 h-24 pt-8 mb-12 mx-auto',
          name: 'Energie&#173;effizienz'
        },
        {
          id: 'design',
          image: design,
          imageClass: 'w-16 h-24 pt-8 mb-12 mx-auto',
          name: 'Design / Ganzglas'
        },
        {
          id: 'page4-weiss-nicht',
          image: weiss_nicht,
          name: 'Weiß nicht / Nichts davon'
        }
      ]
    },
    {
      pageName: 'groesse',
      question: 'Wie groß soll Ihre Automatiktür werden?',
      questionType: 'size',
      next: 'anzahl',
    },
    {
      pageName: 'anzahl',
      question: 'Wie viele Türen dieser Art benötigen Sie?',
      questionType: 'amount',
      next: 'kundenart',
    },
    {
      pageName: 'kundenart',
      question: 'Wo kommt Ihre neue Tür zum Einsatz?',
      questionType: 'single',
      next: 'bauobjekt',
      options: [
        {
          id: 'gewerblich',
          image: kundenart_gewerblich,
          imageClass: 'w-20 h-24 pt-8 mb-12 mx-auto',
          name: 'Gewerbe',
        },
        {
          id: 'privat',
          image: kundenart_privat,
          imageClass: 'w-24 h-24 pt-8 mb-12 mx-auto',
          name: 'Privat',
        }
      ]
    },
    {
      pageName: 'bauobjekt',
      question: 'Wo soll die Schiebetür verbaut werden?',
      questionType: 'zip',
      next: 'angebotanfrage',
    },
    {
      pageName: 'angebotanfrage',
      question: 'Ihr individuelles Angebot ist fast fertig',
      questionType: 'contact',
      next: 'danke',
    },
    {
      pageName: 'danke',
      question: 'Vielen Dank für Ihre Anfrage!',
      questionType: 'thanks'
    }
  ]
}

