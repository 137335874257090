import automatiktuer_angebot from '../images/konfigurator/automatiktuer_angebot_03.jpg';
import React from 'react';
import productData from '../data/product-data';
import ProductCard from './ProductCard';

const ConfiguratorFormThanks = (props) => {

  const criteria = (product) => {
    return [
      '/produkte/automatische-drehtuer/',
      '/produkte/karusselldrehtuer/',
      '/produkte/glasschiebewand/'
    ].includes(product.productPage)
  }

  const filteredProducts = productData.filter(criteria);

  return (
    <div className="text-center">
      <div>In Kürze erhalten Sie ihr individuelles Angebot.
        <br/> Zur Durchsprache der genauen Ausstattung wird sich einer unserer Fachberater bei Ihnen melden.
      </div>
      <div className="flex justify-center"><img src={automatiktuer_angebot} width="180" className="shadow-xl mx-8 my-4" alt="Automatiktuer24 Angebot" /></div>

      <p className="text-xl mt-24 text-gray-600">Andere Kunden interessierten sich auch für folgende Produkte</p>

      <div className="flex flex-wrap flex-stretch sm:flex-row mt-4 max-w-6xl mx-8 justify-center">
        {filteredProducts.map(product => (
          <ProductCard product={product} key={product.productPage} />
        ))}
      </div>
    </div>
  )
}

export default ConfiguratorFormThanks
