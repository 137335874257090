import React, {useState} from 'react';
import automatiktuer_angebot from '../images/konfigurator/automatiktuer_angebot_03.jpg';
import {FormField} from './FormField';
import FormCheckbox from './FormCheckbox';
import {trackEvent, trackScreenView} from '../functions/analytics';

const ConfiguratorFormInputContact = (props) => {
  const {data, updateDoorConfig, handleNext} = props

  const inputDefaults = {
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    privacy: false,
  }

  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set())
  const [forceUpdate, setForceUpdate] = useState(1)

  const inputChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.value
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    errors.has(key) ? validateInput(event.target) : null
  }

  const checkboxChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.checked
    setInput({
      ...input,
      [key]: value,
    })
    // validate on every change
    validateInput({name:key, value:value})
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    checkboxChangeHandler: checkboxChangeHandler,
    input: input,
    errors: errors,
  }

  const validateForm = () => {
    for(const field in inputDefaults) {
      validateInput({name:field, value:input[field]})
    }
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (name, value) => {
    let error;
    switch (name) {
      case 'firstName':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'lastName':
        if (typeof(value) === 'undefined' || value === null || value === '' || value.length < 2) error = true; break;
      case 'phone':
        if (typeof(value) === 'undefined' || value === null || !RegExp('^[0-9\\-\(\)\+\/ ]{7,25}$').test(value)) error = true; break;
      case 'email':
        if (typeof(value) === 'undefined' || value === null || !RegExp("^.+@.+(\\.).+$").test(value)) error = true; break;
      case 'privacy':
        if (typeof(value) === 'undefined' || value === null || value === false) error = true; break;
      default:
        error = false;
    }
    return error;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    if(errors.size > 0) {
      //warn?
      //console.log(errors)
      trackScreenView('Konfigurator Validierungsfehler')
    } else {
      //console.log('all OK')
      updateDoorConfig(data.pageName, input)
      handleNext()
      trackScreenView('Konfigurator gesendet')
      trackEvent('configurator_request')
    }
  }

  return(
    <div className="flex flex-wrap flex-start justify-center mt-3 lg:mt-6 w-full">
      <div className="">
        <img src={automatiktuer_angebot} width="213" height="300" className="shadow-xl mx-8 my-4" alt="Automatiktuer24 Angebot" />
      </div>
      <form id="configuratorContactForm" onSubmit={handleSubmit} autoComplete="on" className="bg-gray-100 shadow-md rounded px-2 sm:px-8 pt-6 pb-8 mb-4 text-left">
        <div className="flex justify-end text-sm text-gray-500 mb-4">*Pflichtfeld</div>

        {FormField({
          fieldId: 'firstName',
          fieldName:'Vorname',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie Ihren Vornamen an.'
        })}
        {FormField({
          fieldId: 'lastName',
          fieldName:'Nachname',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie Ihren Nachnamen an.'
        })}
        {FormField({
          fieldId: 'company',
          fieldName:'Firma',
          required: false,
          handlers: handlers,
        })}
        {FormField({
          fieldId: 'phone',
          fieldName:'Telefon',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie eine gültige Telefonnummer an.'
        })}
        {FormField({
          fieldId: 'email',
          fieldName:'E-Mail',
          required: true,
          handlers: handlers,
          errorText: 'Bitte geben Sie eine gültige E-Mail-Adresse an.'
        })}
        {FormCheckbox({
          fieldId: 'privacy',
          fieldName: <div>Ja, ich stimme der <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> zu.
            <br/>Ein Widerruf ist jederzeit möglich.</div>,
          handlers: handlers,
          errorText: 'Bitte bestätigen Sie die Datenschutzerklärung'
        })}


        <div className="flex items-center justify-end mt-8">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3"
            type="submit"
          >
            Jetzt Angebot erhalten<br/>
            <span className="font-normal text-xs">kostenlos & unverbindlich</span>
          </button>
        </div>

      </form>
    </div>
  )
}

export default ConfiguratorFormInputContact
