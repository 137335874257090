import React, {useState} from 'react'
import Button from './Button'
import {toggleArrayItem} from '../functions/array'
import ConfiguratorFormSelect from './ConfiguratorFormSelect';
import ConfiguratorFormInputSize from './ConfiguratorFormInputSize';
import ConfiguratorFormInputAmount from './ConfiguratorFormInputAmount';
import ConfiguratorFormInputContact from './ConfiguratorFormInputContact';
import ConfiguratorFormThanks from './ConfiguratorFormThanks';
import ConfiguratorFormInputZip from './ConfiguratorFormInputZip';

const Page = (props) => {
  const {data, nextPage, updateDoorConfig, state, ref} = props
  const {doorConfig} = state
  const [selected, setSelected] = useState([])

  const fadeOut = () => {
    let page = document.querySelector('#'+data.pageName)
    page.classList.add('fade-exit-prepare')
  }

  const handleClick = (id) => {
    if(data.questionType === 'single') {
      let newSelected = [id]
      setSelected(newSelected)
      updateDoorConfig(data.pageName, newSelected)

      //with multiple next-options depending on answer
      const currentObj = data.options.find(x => x.id === id)
      if(currentObj.next) {
        handleNext(currentObj.next)
      } else {
        handleNext()
      }
    }
    if(data.questionType === 'multi') {
      let newSelected = [...selected]
      toggleArrayItem(newSelected, id)
      setSelected(newSelected)
    }
  }

  const handleNext = (nextPageName = data.next) => {
    if(data.questionType === 'multi') {
      updateDoorConfig(data.pageName, selected)
    }
    setTimeout(fadeOut, 400)
    setTimeout(nextPage, 800, data.pageName, nextPageName)
  }

  const NextButton = () => {
    if(data.questionType === 'multi' && selected.length === 0) {
      return(<Button className="opacity-50 cursor-not-allowed font-bold">weiter</Button>)
    } else if(data.questionType === 'multi') {
      return(<Button className="font-bold" onClick={() => handleNext()}>weiter</Button>)
    }
    else {
      return('')
    }
  }


  return(
    <div id={data.pageName} className={props.class}>
      <p className="text-2xl lg:text-3xl font-bold">
        {data.question}
      </p>
      <p className="text-xl lg:text-2xl">
        {data.description}
      </p>

      <div>
        {(data.questionType === 'single' || data.questionType === 'multi') ? <ConfiguratorFormSelect options={data.options} handleClick={handleClick} selected={selected} /> : ''}
        {(data.questionType === 'size') ? <ConfiguratorFormInputSize data={data} doorConfig={doorConfig} updateDoorConfig={updateDoorConfig} handleNext={handleNext} /> : ''}
        {(data.questionType === 'amount') ? <ConfiguratorFormInputAmount data={data} doorConfig={doorConfig} updateDoorConfig={updateDoorConfig} handleNext={handleNext} /> : ''}
        {(data.questionType === 'zip') ? <ConfiguratorFormInputZip data={data} doorConfig={doorConfig} updateDoorConfig={updateDoorConfig} handleNext={handleNext} /> : ''}
        {(data.questionType === 'contact') ? <ConfiguratorFormInputContact data={data} doorConfig={doorConfig} updateDoorConfig={updateDoorConfig} handleNext={handleNext} /> : ''}
        {(data.questionType === 'thanks') ? <ConfiguratorFormThanks /> : ''}
      </div>

      <div className="mt-3">
        <NextButton />
      </div>
    </div>
  )
}

export default Page;
