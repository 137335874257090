import * as entities from 'entities';
import React from 'react';

const ConfiguratorFormSelect = (props) => {
  const {options, handleClick, selected} = props

  return(
    <div className="flex flex-wrap flex-stretch justify-center mt-3 lg:mt-6 w-full">
      {options.map(option => (
        <div
          id={option.id}
          onClick={() => handleClick(option.id)}
          key={option.id}
          className={`card-master rounded-lg overflow-hidden w-40 sm:w-56 m-1 sm:m-2 hover:bg-green-50 border-2 sm:border-4 ${
            selected.includes(option.id) ? 'border-green-600 bg-green-50 confirmBlink' : 'border-gray-200'
          }`}>
          <img src={option.image} alt="Icon" className={option.imageClass ? option.imageClass : "object-cover object-top w-full" } />
          <div className="p-2 sm:p-6">
            <p className="font-semibold text-xl lh:h-16 text-blue-600">
              {entities.decodeHTML(option.name)}
            </p>
          </div>
        </div>
      ))
      }
    </div>
  )
}

export default ConfiguratorFormSelect
