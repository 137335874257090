import React, {useState} from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import helpIcon from '../svg/help_light.svg';
import hilfe_durchgang_hoehe from '../images/konfigurator/hilfe_automatiktuer_groesse_durchgang_hoehe.jpg';
import hilfe_durchgang_breite from '../images/konfigurator/hilfe_automatiktuer_groesse_durchgang_breite.jpg';
import hilfe_gesamt_hoehe from '../images/konfigurator/hilfe_automatiktuer_groesse_gesamt_hoehe.jpg';
import hilfe_gesamt_breite from '../images/konfigurator/hilfe_automatiktuer_groesse_gesamt_breite.jpg';

const ConfiguratorFormInputSize = (props) => {
  const {data, doorConfig, updateDoorConfig, handleNext} = props

  const defaults = {
    durchgangHoehe: '',
    durchgangBreite: '',
    gesamtHoehe: '',
    gesamtBreite: '',
  }
  const [input, setInput] = useState({
    ...defaults,
    ...doorConfig[data.pageName],
  })

  const inputChangeHandler = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setInput({
      ...input,
      [key]: value,
    })
  }

  const handleNextButton = () => {
    updateDoorConfig(data.pageName, input)
    handleNext()
  }

  return(
    <div className="flex flex-wrap flex-start justify-center mt-3 lg:mt-6 w-full">
      <form className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4 text-left">
        <div className="mb-4">
          <div className="block text-gray-600 text-md font-bold mb-2">
            Durchgang
          </div>
          <div className="flex flex-row items-start items-center">
            <label className="text-gray-500 text-sm font-bold mx-3 w-12" htmlFor="durchgangHoehe">Höhe</label>
            <input
              className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md"
              id="durchgangHoehe"
              name="durchgangHoehe"
              type="number"
              placeholder="z.B. 2600"
              defaultValue={input.durchgangHoehe}
              onChange={(e) => inputChangeHandler(e)}
            />
            <div className="px-2 text-gray-500 text-sm">mm</div>
            <Tippy theme="light" content={
              <span><b>Lichte Durchgangshöhe</b><br/>
                beinhaltet die Höhe des Durchgangs,<br/> ohne Oberlicht.<br/>
                <img src={hilfe_durchgang_hoehe} style={{width:300}} alt="Automatiktür Durchgangshöhe Skizze" /></span>
            }>
              <img src={helpIcon} className="ml-1 w-5" alt="Hilfe" />
            </Tippy>
          </div>
        </div>
        <div className="mb-4">
          <div className="flex flex-row items-start items-center">
            <label className="text-gray-500 text-sm font-bold mx-3 w-12" htmlFor="durchgangBreite">Breite</label>
            <input
              className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md"
              id="durchgangBreite"
              name="durchgangBreite"
              type="number"
              placeholder="z.B. 1800"
              defaultValue={input.durchgangBreite}
              onChange={(e) => inputChangeHandler(e)}
            />
            <div className="px-2 text-gray-500 text-sm">mm</div>
            <Tippy theme="light" content={
              <span><b>Lichte Durchgangsbreite</b><br/>
                beinhaltet die Breite des Durchgangs,<br/> ohne Seitenteile.<br/>
                <img src={hilfe_durchgang_breite} style={{width:300}} alt="Automatiktür Durchgangsbreite Skizze" /></span>
            }>
              <img src={helpIcon} className="ml-1 w-5" alt="Hilfe" />
            </Tippy>
          </div>
        </div>
        <div className="mb-4">
          <div className="block text-gray-600 text-md font-bold mb-2">
            Gesamt
          </div>
          <div className="flex flex-row items-start items-center">
            <label className="text-gray-500 text-sm font-bold mx-3 w-12" htmlFor="gesamtHoehe">Höhe</label>
            <input
              className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md"
              id="gesamtHoehe"
              name="gesamtHoehe"
              type="number"
              placeholder="z.B. 3200"
              defaultValue={input.gesamtHoehe}
              onChange={(e) => inputChangeHandler(e)}
            />
            <div className="px-2 text-gray-500 text-sm">mm</div>
            <Tippy theme="light" content={
              <span><b>Gesamthöhe</b><br/>
                beinhaltet die gesamte Höhe der<br/> Türkonstruktion, inkl. Oberlicht.<br/>
                <img src={hilfe_gesamt_hoehe} style={{width:300}} alt="Automatiktür Gesamthöhe Skizze" /></span>
            }>
              <img src={helpIcon} className="ml-1 w-5" alt="Hilfe" />
            </Tippy>
          </div>
        </div>
        <div className="mb-4">
          <div className="flex flex-row items-start items-center">
            <label className="text-gray-500 text-sm font-bold mx-3 w-12" htmlFor="gesamtBreite">Breite</label>
            <input
              className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-md"
              id="gesamtBreite"
              name="gesamtBreite"
              type="number"
              placeholder="z.B. 2750"
              defaultValue={input.gesamtBreite}
              onChange={(e) => inputChangeHandler(e)}
            />
            <div className="px-2 text-gray-500 text-sm">mm</div>
            <Tippy theme="light" content={
              <span><b>Gesamtbreite</b><br/>
                beinhaltet die gesamte Breite der<br/> Türkonstruktion, inkl. Seitenteile.<br/>
                <img src={hilfe_gesamt_breite} style={{width:300}} alt="Automatiktür Gesamtbreite Skizze" /></span>
            }>
              <img src={helpIcon} className="ml-1 w-5" alt="Hilfe" />
            </Tippy>
          </div>
        </div>
        <div className="flex items-center justify-end mt-8">
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md"
            type="button"
            onClick={handleNextButton}
          >
            Weiß nicht
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-md ml-3"
            type="button"
            onClick={handleNextButton}
          >
            weiter
          </button>
        </div>
      </form>
    </div>
  )
}

export default ConfiguratorFormInputSize
