import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import ConfiguratorPageTemplate from '../components/Configurator'
import data from '../data/configurator-data'
import {navigate} from 'gatsby';
import {generateID} from '../functions/id';
import {getOrCreatePID} from '../functions/cookie';
import {postStep} from '../functions/axios';
import {capitalizeFirstLetter} from '../functions/stringHelper';
import {trackScreenView} from '../functions/analytics';

const Configurator = (props) => {

  let activeHash = 'start'
  if(props.location.hash) activeHash = props.location.hash.replace('#step-','')

  const [doorConfig, setDoorConfig] = useState({})
  const [activePage, setActivePage] = useState(activeHash)
  const [sid, setSid] = useState('s'+generateID())
  const [pid, setPid] = useState(getOrCreatePID())
  const state = {doorConfig, setDoorConfig, activePage, activeHash, setActivePage, sid, pid}

  const updateDoorConfig = (pageName, pagaData) => {
    const newDoorConfig = {
      ...doorConfig,
      [pageName]: pagaData,
    }
    postStep({doorConfig:newDoorConfig, activePage, activeHash, sid, pid})
    trackScreenView('Konfigurator ' + capitalizeFirstLetter(activePage))
    setDoorConfig(newDoorConfig)
  }

  if(activeHash !== 'start' && Object.keys(doorConfig).length === 0) {
    navigate(`/automatiktuer-konfigurator/`)
  }

  const nextPage = (currentPageName, nextPageName) => {
    const current = document.querySelector('#'+currentPageName)
    current.classList.add('fade-exit-active')
    const next = document.querySelector('#'+nextPageName)
    next.classList.add('fade-enter-active')
    navigate(`/automatiktuer-konfigurator/#step-${nextPageName}`)
    setActivePage(nextPageName)
    if (typeof window !== 'undefined') window.scroll(0,0)
  }

  return(
    <div className="application">
      <Helmet>
        <title>Automatische Schiebetür konfigurieren - {capitalizeFirstLetter(activePage)} | Automatiktür24</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Ihre Automatiktüren in 3 Minuten online konfigurieren" />
        <html lang="de" />
      </Helmet>
      <Layout>

        <section>

          <div className="container mx-auto px-2 lg:px-8 flex flex-col mb-10">
            <div id="headerBox" className="hidden md:block text-center lg:text-left text-gray-500 bg-gray-100 mb-10 mt-8">
              <h1 className="text-2xl lg:text-3xl xl:text-3xl font-bold leading-none pl-2 pt-2">
                Automatiktür Konfigurator
              </h1>
              <p className="text-lg lg:text-xl font-light p-2">
                Die 3-Minuten-Automatiktür-Beratung.<br/>
                Kostenloses & unverbindliches Angebot für Ihre individuelle Automatiktür erhalten.
              </p>
            </div>

            <div id="questionBox" className="text-center mt-4 mb-32 md:mt-0">
              {
                data.pages.map(page => {
                  return(
                    <ConfiguratorPageTemplate
                      key={page.pageName}
                      data={page}
                      nextPage={nextPage}
                      updateDoorConfig={updateDoorConfig}
                      state={state}
                      class={(activeHash === page.pageName) ? '' : 'fade-enter'} />
                  )
                })
              }
            </div>
          </div>

        </section>

      </Layout>
    </div>
  );
}

export default Configurator
